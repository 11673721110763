// venues table
table{
    thead{
        tr{
            &:last-child{
                display: none;
            }
            th{
                &:nth-child(1){
                    padding: 1.5rem 2rem !important;
                }
            }
        }
    }
    .tblHeader{
        color: $gray;
        font-family: 'Barlow';
        font-size: 1.5rem;
        font-style: normal;
        @extend .fw_400;
        line-height: normal;
        padding: 1.5rem 0;
        background: $bdr_clr;
        border-bottom: 0.1rem solid $bdr_clr;
    }
    .tblRows,.tblHeader,.tblColumns{
        border-radius: 0 !important;
        // padding-left: 2rem !important;
        padding-left: 0rem !important;

    }
    .tblRows{
        transition: all 0.5s ease-in-out;
        border-bottom: 0.1rem solid $bdr_clr;
        background: $bg_gray;
        cursor: pointer;
        &:hover{
            background: $hover_color;
        }
    }
    .tblColumns{
        padding: 1.5rem 2rem;
    }
}


.films_tbl,.users_tbl,.castCrew_tbl,.film_screening_tbl,.screening_schedule_tbl,.events_tbl,.article_tbl,.venues_tbl,.question_tbl{
    overflow-x: auto;
    @extend .scrollbar;
    &::-webkit-scrollbar {
        cursor: pointer;
        height: 0.6rem; 
    }
}
.status_dropdown{
    z-index: 1;
    button{
        color: $sky_light;
        font-family: 'Barlow';
        font-size: 1.6rem;
        @extend .fw_600;
        line-height: normal;
        background: transparent;
        padding: 0;
        @extend .justify_content_start;
        @extend .align_items_center;
        &[aria-expanded=true]
        {
            .rotate{
                transform: all 0.5s ease-in-out;
                transform: rotate(90deg);
            }
        }
        img{
            min-width: 1.6rem;
            max-width: 1.6rem;
            max-height: 1.6rem;
            min-height: 1.6rem;
            margin-top: 0.3rem;
        }
    }
}
.action_dropdown{
    button{
        @extend .position_relative;
        max-width: 3.2rem;
        max-height: 3.2rem;
        z-index: 1;
        &[aria-expanded="true"]{
            border: 0.1rem solid $bdr_clr;
            background: $bg_gray;
        }
        &::after{
            content: '';
            @extend .position_absolute;
            // top: 2.2rem;
            // left: 2.2rem;
            width: 2rem;
            height: 2rem;
            background: url("../images/svg/tbl_action_icon.svg") no-repeat;
        }
        svg{
            display: none;
        }

    }
}

.tblColumns{
    img{
        min-width: 8rem;
        max-width: 8rem;
        width: 100%;
        min-height: 4.5rem;
        max-height: 4.5rem;
        height: 100%;
    }
}

tbody{
    tr{
        background: $dark_bg;
        td{
            height: 8.4rem;
            &:nth-child(1){
                padding: 1.5rem 2rem !important;
            }
            span,p{
                color: $gray !important;
                font-family: 'Barlow';
                font-size: 1.8rem;
                font-style: normal;
                @extend .fw_600;
                line-height: 2.7rem;
                @extend .line_clamp;
            }
        }
    }
}
// film tables

.films_tbl{
    thead,tbody{
        tr{
            th,td{
                &:nth-child(1){
                    min-width: 12rem;
                    width: 12rem;
                }
                &:nth-child(2){
                    width: 28.8rem;
                    min-width: 28.8rem;
                }
                &:nth-child(3){
                    width: 20.5rem;
                    min-width: 20.5rem;
                    padding-right: 0;
                    p{
                        max-width: 16.5rem;
                        width: 100%;
                        
                    }
                }
                &:nth-child(4){
                    width: 20.2rem;
                    min-width: 20.2rem;
                }
                &:nth-child(5){
                    width: 10.4rem;
                    min-width: 10.4rem;
                }
                &:nth-child(6){
                    width: 14.9rem;
                    min-width: 14.9rem;
                }
                &:nth-child(7){
                    width: 8.4rem;
                    min-width: 8.4rem;
                }
            }
        }
    }
}

// users tables
.users_tbl{
    thead,tbody{
        tr{
            th,td{
                &:nth-child(1),&:nth-child(2){
                    min-width: 53.4rem;
                    width: 53.4rem;
                }
                &:nth-child(3){
                    min-width: 8.4rem;
                    width: 8.4rem;
                }
            }
        }
    }
}

// cast & crew tables

.castCrew_tbl{
    thead,tbody{
        tr{
            th,td{
                &:nth-child(1){
                    width: 7.8rem;
                    min-width: 7.8rem;
                    padding-right: 1rem !important;
                }
                &:nth-child(2){
                    width: 27.8rem;
                    min-width: 27.8rem;
                }
                &:nth-child(3),&:nth-child(4){
                    width: 35.6rem;
                    min-width: 35.6rem;
                }
                &:nth-child(5){
                    width: 8.4rem;
                    min-width: 8.4rem;
                }
            }
        }
    }
    .tblColumns{
        img{
            min-width: 4.8rem;
            max-width: 4.8rem;
            width: 100%;
            min-height: 4.8rem;
            max-height: 4.8rem;
            height: 100%;
        }
    }
}

// film screening table

.film_screening_tbl{
    margin: 4rem 0;
    max-height: 30rem;
    overflow: auto;
    // overflow-x: hidden;
    @extend .scrollbar;
    thead{
        tr{
            th{
                color: $gray;
                font-family: 'Barlow';
                font-size: 1.5rem;
                font-style: normal;
                @extend .fw_400;
                line-height: normal;
                padding: 1.5rem 2rem;
            }
        }
    }
    tbody{
        tr{
            background: $dark_bg;
            td{
                height: 8.4rem;
                span{
                    color: $gray;
                    font-family: 'Barlow';
                    font-size: 1.8rem;
                    font-style: normal;
                    @extend .fw_600;
                    line-height: 2.7rem;
                }
            }
        }
    }
    tbody,thead{
        tr{
            td,th{
                &:nth-child(1){
                    width: 42rem;
                    max-width: 42rem;
                }
                &:nth-child(2){
                    width: 32.8rem;
                    max-width: 32.8rem;
                }
                &:nth-child(3){
                    width: 35.6rem;
                    max-width: 35.6rem;
                }
            }
        }
    }
}

// screening schedule table
.screening_schedule_tbl{
    thead{
        tr{
            th{
                &:nth-child(1){
                    width: 42rem;
                    min-width: 42rem;
                }
                &:nth-child(2){
                    width: 29.2rem;
                    min-width: 29.2rem;
                }
                &:nth-child(3){
                    width: 35.6rem;
                    min-width: 35.6rem;
                }
                &:nth-child(4){
                    width: 8.4rem;
                    min-width: 8.4rem;
                }
            }
        }
    }
}


// events tables
.events_tbl{
    thead{
        tr{
            th{
                &:nth-child(1),&:nth-child(3){
                    width: 15.3rem;
                    min-width: 15.3rem;
                }
                &:nth-child(2){
                    width: 34.8rem;
                    min-width: 34.8rem;
                }
                &:nth-child(4){
                    width: 17.9rem;
                    min-width: 17.9rem;
                }
                &:nth-child(5){
                    width: 23.4rem;
                    min-width: 23.4rem;
                }
                &:nth-child(6){
                    width: 8.4rem;
                    min-width: 8.4rem;
                }
            }
        }
    }
}

// article table
.article_tbl{
    thead,tbody{
        tr{
            th,td{
                &:nth-child(1){
                    width: 12rem;
                    min-width: 12rem;
                }
                &:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5){
                    width: 23.7rem;
                    min-width: 23.7rem;
                }
                &:nth-child(5){
                    padding-right: 0;
                    p{
                        width: 23.7rem;
                        min-width: 23.7rem;
                        color: $sky_light !important;
                    }
                }
                &:nth-child(6){
                    width: 8.4rem;
                    min-width: 8.4rem;
                }
            }
        }
    }
    tbody{
        tr{
            td{
                &:nth-child(5){
                    span{
                        color: $sky_light;
                    }
                }
            }
        }
    }
}


// venues_table{
.venues_tbl{
    thead,tbody{
        tr{
            th,td{
                &:nth-child(1){
                    width: 32.2rem;
                    min-width: 32.2rem;
                }
                &:nth-child(2){
                    width: 74.6rem;
                    min-width: 74.6rem;
                }
                &:nth-child(3){
                    width: 8.4rem;
                    min-width: 8.4rem;
                }
            }
        }
    }
}

// questions table
.question_tbl{
    margin: 4rem 0;
    max-height: 30rem;
    overflow: auto;
    tbody{
        // min-height: 67.2rem;
        // overflow-y: auto;
        // &::-webkit-scrollbar{
        //     display: block;
        // }
    }
    thead,tbody{
        tr{
            th,td{
                &:nth-child(1){
                    width: 21.6rem;
                    max-width: 21.6rem;
                }
                &:nth-child(2){
                    width: 22.1rem;
                    max-width: 22.1rem;
                }
                &:nth-child(3){
                    width: 66.1rem;
                    max-width: 66.1rem;
                }
            }
        }
    }
}
