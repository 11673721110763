// Barlow Semi Condensed

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-SemiBold.eot');
    src: url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-SemiBold.woff2') format('woff2'),
        url('../fonts//barlowSemiCondensed/barlowsemicondensed-bold.woff') format('woff'),
        url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-SemiBold.ttf') format('truetype'),
        url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-SemiBold.svg#BarlowSemiCondensed-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-Black.eot');
    src: url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-Black.woff2') format('woff2'),
        url('../fonts//barlowSemiCondensed/BarlowSemiCondensed-Black.woff') format('woff'),
        url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-Black.ttf') format('truetype'),
        url('../fonts/barlowSemiCondensed/BarlowSemiCondensed-Black.svg#BarlowSemiCondensed-Black') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

//  Barlow
@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow/Barlow-Regular.eot');
    src: url('../fonts/Barlow/Barlow-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Barlow/Barlow-Regular.woff2') format('woff2'),
        url('../fonts/Barlow/Barlow-Regular.woff') format('woff'),
        url('../fonts/Barlow/Barlow-Regular.ttf') format('truetype'),
        url('../fonts/Barlow/Barlow-Regular.svg#Barlow-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow/Barlow-SemiBold.eot');
    src: url('../fonts/Barlow/Barlow-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Barlow/Barlow-SemiBold.woff2') format('woff2'),
        url('../fonts/Barlow/Barlow-SemiBold.woff') format('woff'),
        url('../fonts/Barlow/Barlow-SemiBold.ttf') format('truetype'),
        url('../fonts/Barlow/Barlow-SemiBold.svg#Barlow-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow/Barlow-Medium.eot');
    src: url('../fonts/Barlow/Barlow-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Barlow/Barlow-Medium.woff2') format('woff2'),
        url('../fonts/Barlow/Barlow-Black.woff') format('woff'),
        url('../fonts/Barlow/Barlow-Medium.ttf') format('truetype'),
        url('../fonts/Barlow/Barlow-Medium.svg#Barlow-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


.barlowSemiCondensed{font-family: 'Barlow Semi Condensed'; font-weight: 600;font-style: normal;font-display: swap;}
.barlow_regular{ font-family: 'Barlow'; font-weight: 400;font-style: normal;font-display: swap;}
.barlow_medium{  font-family: 'Barlow'; font-weight: 500;font-style: normal;font-display: swap;}
.barlow_semi_bold{font-family: 'Barlow';font-weight: 600;font-style: normal;font-display: swap;}

// roboto

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Regular.eot');
    src: url('../fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Regular.woff') format('woff'),
        url('../fonts/roboto/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.roboto_regular{ font-family: 'Roboto'; font-weight: normal;font-style: normal;font-display: swap;}
