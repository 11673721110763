.login_form {
    .head_title {
        margin-bottom: 2.4rem;
    }

    .form_group {
        @extend .position_relative;
        margin-bottom: 2.7rem;
        
        label {
            @extend .fs_16;
            line-height: 2.2rem;
            @extend .d_none;
        }

        &.remem_forgot {
            @extend .d_flex_center;
            @extend .justify_content_between;
            .forgot {
                .forgot_pass {
                    @extend .cursor_pointer;
                    color: $white;
                    @extend .barlow_regular;
                    @extend .fs_16;
                    @extend .fw_400;
                }
            }
        }
        .error-message {
            padding: 0.5rem 0;
            @extend .d_block;
        }

        .custom-loader-primary {
            padding-right: 2rem;
        }

        .btn_lord {
            @extend .position_absolute;
            top: 50%;
            right: 2.4rem;
            transform: translateY(-50%);
        }

        .sky_border_btn {
            min-width: auto;
            max-width: unset;
            width: 100%;
            min-height: 5rem;
        }

        .custom_form_input {
            height: 4.3rem;
            width: 100%;
            padding: 0rem 4rem 1.6rem 0.75rem;
            border: 0;
            border-bottom: 0.1rem solid $gray;
            color: $white;
            @extend .fs_24;
            @extend .barlow_regular;
            letter-spacing: -0.48px;
            background: transparent;

            &.border_red {
                border-bottom: 0.1rem solid $err_red;
            }
        }

        .remember {
            .check {
                @extend .position_relative;

                .check_input {
                    @extend .position_absolute;
                    z-index: 99999;
                    width: 1.8rem;
                    height: 1.8rem;
                    opacity: 0;
                    top: 0.4rem;
                    @extend .cursor_pointer;
                }

                .checkmark {
                    @extend .position_absolute;
                    top: 0.4rem;
                    left: 0;
                    height: 1.8rem;
                    width: 1.8rem;
                    background-color: transparent;
                    border: 0.1rem solid $white;
                    border-radius: 0.2rem;

                    &::after {
                        content: "";
                        display: block;
                        width: 0.8rem;
                        height: 0.8rem;
                        background: url("../images/svg/checkBox_true_icon.svg") no-repeat;
                        @extend .position_absolute;
                        left: 0.4rem;
                        top: 0.4rem;
                        opacity: 0;
                    }
                }
            }

            .check_input:checked~.checkmark {
                background-color: $black;
                border: 0.1rem solid $white;
            }

            .check_input:checked~.checkmark::after {
                opacity: 1;
            }

            .remember_text {
                color: $white;
                @extend .barlow_regular;
                @extend .fs_16;
                line-height: 2.4rem;
                @extend .fw_400;
                padding-left: 2.8rem;

                span {
                    margin-left: 0.3rem;

                    u {
                        text-decoration: none !important;
                        @extend .position_relative;
                        display: inline-block;

                        &::after {
                            content: '';
                            @extend .position_absolute;
                            bottom: 0.4rem;
                            left: 0;
                            width: 100%;
                            height: 0.1rem;
                            background: $white;
                        }
                    }
                }
            }
        }
        .error_message{
            min-height: unset;
        }
    }
}