html {
    font-size: 10px;
  }
  
  html,body{
    overflow-x: hidden;
    height: 100%;
  }
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline-color: none !important;
    --tw-ring-offset-color: none !important;
  }
  
  body {
    @extend .fs_15;
    line-height: 1.8rem;
    color: $black;
    background: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    min-height: 100vh;
  }
  
  .container {
    width: 100%;
    max-width: 132.6rem;
    padding: 0 1.5rem;
    @extend .m_auto;
  }
  .inner_container {
    width: 100%;
    max-width: 107rem;
    padding: 0 1.5rem;
    @extend .m_auto;
  }
  .one_sided_container{
    margin-left: calc((100% - min(90%, 107rem)) / 2);
    overflow-x: auto;
    @extend .position_relative;
  }
  .login_form{
    @extend .position_relative;
    max-width: 46.4rem;
    min-width: 46.4rem;
    width: 100%;
    @extend .m_auto;
  }
  
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    // @include appearance(none);
    @extend .cursor_pointer;
  }
  
  input[type="password"] {                                                                                                        
    font-size: 1.8rem;
    line-height: 2rem;
    font-style: normal;
    font-weight: normal;
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.3rem;
  }
  
  input:not([type="radio"], [type="checkbox"]) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  input {
    outline: 0;
    font-size: 1.8rem;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:autofill, input:-webkit-autofill-strong-password, input:-webkit-autofill-strong-password-viewable, input:-webkit-autofill-and-obscured{
    -webkit-text-fill-color: $gray !important;
  }
  input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{
    -webkit-appearance: none;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .custom-loader-secondary {
    i {
      background: transparent;
      border-width: 6px;
    }
  
    .relative {
      width: 10rem;
      height: 10rem;
    }
  }
  
  .custom-loader-wrapper {
    @extend .position_fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  
  .btn_lord {
    img {
      animation: spin_witch .8s linear infinite;
    }
  }

  .sub_loader {
    @extend .position_absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    backdrop-filter: blur(10px);
    .btn_lord{
      &.loader_set{
        display: flex;
        width: 5rem;
        height: 5rem;
      }
    }
  }
  
  @keyframes spin_witch {
    0% {
      transform: rotate(-360deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }

  .searchBox{
    font-family: 'Barlow';
    font-size: 1.6rem;
    @extend .fw_500;
    line-height: normal;
    color: $gray;
    border: 0.5px solid $sky_light;
    background: #1A1A1A !important;
    padding: 1.3rem 1.6rem 1.3rem 4.4rem;
    width: 100%;
  }
  .general_wrapper{
    border-right: 0.1rem solid $bdr_clr;
  }
  .left_wrapper{
  }
  .left_wrapper,.sidebar{
    height: 100%;
    width: 22.4rem;
    // max-width: 22.4rem;
    // min-width: 22.4rem;
    // width: 100%;
    background-color: $dark_bg;
    border-right: 0.1px solid $bdr_clr;
  }
  .right_wrapper{
    width: calc(100% - 22.4rem);
    // width: 100%;
    background: linear-gradient(180deg, #0F0F0F 0%, #000 100%);
    .right_inner_wrapper{
      margin: 11.2rem 3.2rem 3.2rem;
    }
  }

  .error_message{
    min-height: 1.9rem;
    margin-top: 0.7rem;
  span{
    color: $err_red;
    float: right;
    font-family: Barlow;
    font-size: 1.3rem;
    font-style: normal;
    @extend .fw_400;
    line-height: normal;
    display: inline-block;
  }
}
.dropdown{
  &.price_dropdown{
    button{
      padding-left: 6.4rem;
    }
  }
  button{
    border-radius: 0 !important;
    height: 4.7rem !important;
    border: 0.1rem solid $bdr_clr;
    background: $bg_gray;
    outline: none;
    &[data-focus="true"]{
      border-color: $bdr_clr;
    }
    span{
      color: $mid_gray;
      font-family: 'Barlow';
      font-size: 1.6rem;
      @extend .fw_500;
      line-height: normal;
    }
    svg{
      width: 1.6rem;
      height: 1.6rem;
      right: 1.55rem;
      path{
        stroke: $gray;
      }
    }
  }
}

[data-focus-visible= true]{
  outline: none !important;
}

div[data-slot="content"]{
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0.1rem solid $bdr_clr;
  div[data-slot="listboxWrapper"]{
    max-height: 38rem !important;
  }
}
div[data-slot="listbox"]{
  padding: 0;
}
ul[data-slot="listbox"],ul[data-slot="list"] {
  background: $dark_bg;
  li{
    padding: 1.6rem;
    border-radius: 0 !important;
    color: $gray;
    outline: none !important;
    span,div[data-slot="empty-content"]{
      color: $gray;
      font-family: 'Barlow';
      font-size: 1.6rem;
      font-style: normal;
      @extend .fw_400;
      line-height: normal;
    }
    svg{
      width: 1.6rem;
      height: 1.6rem;
      @extend .position_absolute;
      right: 1.6rem;
      polyline{
        stroke: $sky_light;
      }
    }
  }
}

div[data-orientation="vertical"]:has( .autocomplete_items){
  scrollbar-width: auto !important;
  -webkit-mask-image: unset;
  mask-image: unset;
}
ul[data-slot="listbox"],div[data-orientation="vertical"]:has( .autocomplete_items){
  overflow-y: auto;
  max-height: 38rem;
  &::-webkit-scrollbar {
    display: block;
    width: 0.6rem; 
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: $gray;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $bdr_clr; 
  }
}
div[data-slot="base"]{
  padding: 0;
  ul[data-slot="list"]{
    background: $bg_gray;
    // height: 50rem;
    overflow-y: auto;
    li{
      @extend .align_items_center;
      .avatar_img{
        width: 4rem;
        height: 4rem;
        margin-right: 1.2rem;
      }
    }
  }  
}
.textarea_field{
  div[data-slot="input-wrapper"]{
    border-radius: 0 !important;
    height: 4.7rem !important;
    border: 0.1rem solid $bdr_clr;
    background: $bg_gray;
    height: 12rem !important;
    textarea{
      color: $gray !important;
      font-family: 'Barlow';
      font-size: 1.6rem;
      font-style: normal;
      @extend .fw_400;
      line-height: normal;
    }
  }
}

.uploaded_trailer{
  border: 0.1rem solid $bdr_clr;
  background: $bg_gray;
  padding: 3.8rem 1.6rem;
  .uploaded_trailer_wrapper{
    .upload_video{
      max-width: 16rem;
      min-width: 16rem;
      width: 100%;
      height: 9rem;
      margin-right: 1.6rem;
    } 
  }
}

// delete icon
.delete_icon{
  width: 1.6rem;
  height: 1.6rem;
  @extend .position_absolute;
  top: 1.6rem;
  right: 5.6rem;
  z-index: 9;
  cursor: pointer;
}

.media_delete{
  max-width: 20.4rem;
  min-width: 20.4rem;
  width: 100%;
  .remove_btn{
    right: 0;
    top: -1rem;
    width: 100%;
    height: 100%;
  }
  .delete_icon{
    &::after{
      content: '';
      @extend .position_absolute;
      top: 0;
      left: -1.6rem;
      width: 0.1rem;
      height: 1.6rem;
      background: $bdr_clr;
    }
  }
}

// calender_icon

.calender_icon{
  @extend .position_absolute;
  top: 1.6rem;
  right: 1.6rem;
  width: 1.6rem;
  height : 1.6rem;
}

.tooltip{
  padding: 1.6rem;
  h2,ul li{
    font-family : 'Barlow';
    @extend .fw_700;
    font-size: 1.3rem;
    line-height: 2.1rem;
    color: $gray;
  }
  ul{
    margin-top: 0.8rem;
    li{
      @extend .position_relative;
      margin-left: 1.6rem;
      &::before{
        content: '';
        @extend .position_absolute;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        background: #D9D9D9;
        top: 0.7rem;
        left: -0.7rem;
      }
    }
  }
}

// action dropdown

.action_trigger{
  @extend .position_absolute;
  right: -1.5rem;
}
.action_dropdown_items{
  border: 0.1rem solid $bdr_clr;
  background: $bg_gray;
  box-shadow: 0px 40px 80px 0px $black;
  padding: 0;
  ul{
    li{
      padding: 1.85rem 1.6rem;
      border-radius: 0 !important;
      &:not(:last-child){
        border-bottom: 0.1rem solid $bdr_clr;
      }
      span{
        color: $gray;
        font-family: 'Barlow';
        font-size: 1.6rem;
        font-style: normal;
        @extend .fw_400;
        line-height: normal;
        @extend .d_flex_align_center;
        img{
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.2rem;
        }
      }
    }
  }
}

// pagination
.pagination{
  @extend .d_flex;
  @extend .justify_content_end;
  margin-top: 4rem;
  button{
    border-radius: 0;
    color: $gray;
    font-family: 'Barlow';
    font-size: 1.6rem;
    font-style: normal;
    @extend .fw_400;
    line-height: normal;
    height: 4rem;
    padding-left:3.1rem;
    background: $bg_gray;
    border: 0.1rem solid $bdr_clr;
    max-width: 7.3rem;
    justify-content: unset;
    @extend .position_relative;
    &:first-child{
      &::after{
        content: '';
        @extend .position_absolute;
        top: 1.1rem;
        left: 0.9rem;
        width: 1.6rem;
        height: 1.6rem;
        background: url("../images/svg/calender_prev_icon.svg") no-repeat;
      }
    }
    &:last-child{
      padding-left:1rem;
      padding-right: 3rem;
      &::after{
        content: '';
        @extend .position_absolute;
        top: 1.1rem;
        right: 1rem;
        width: 1.6rem;
        height: 1.6rem;
        background: url("../images/svg/calender_next_arrow.svg") no-repeat;
      }
    }
  }
  nav{
    ul{
      gap: 0;
      span{
        @extend .d_none;
      }
      li{
        border-radius: 0;
        color: $mid_gray;
        font-family: 'Barlow';
        font-size: 1.3rem;
        @extend .fw_400;
        line-height: normal;
        height: 4rem;
        width: 4.6rem;
        background: $bg_gray;
        border-top: 0.1rem solid $bdr_clr;
        border-bottom: 0.1rem solid $bdr_clr;
        border-right: 0.1rem solid $bdr_clr;
        &[data-active="true"]{
          color: $sky_light;
          font-size: 1.6rem;
          @extend .fw_700;
        }
      }
    }
  }
}

// status
.status{
  span{
    font-family: 'Barlow';
    font-size: 1.6rem;
    @extend .fw_600;
    line-height: normal;
    margin-left: 1.5rem;
    @extend .position_relative;
    &::before{
      content: '';
      @extend .position_absolute;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      top: 0.9rem;
      left: -1.5rem;
      z-index: 9;
    }
    &.published{
      &::before{
        background: $sky_light;
      }
    }
    &.unpublished{
      &::before{
        background: $err_red;
      }
    }
    &.draft{
      &::before{
        background: $lime_green;
      }
    }
    
  }
}

.accordion{
  button{
    padding: 0;
    span[aria-hidden="true"]{
      svg{
        width: 1.6rem;
        height: 1.6rem;
        transform: rotate(-90deg);
        path{
          stroke: $gray;
        }
      }
      &[data-open="true"]{
        transform: rotate(-180deg);
      }
    }
  }
}

  // radio button
  .rdo_lable{
    z-index: 1;
    span{
      &.text-foreground{
        color: $gray;
        font-family: 'Barlow';
        font-size: 1.6rem;
        @extend .fw_500;
        line-height: 2.4rem;
        margin-left: 0.8rem;
      }
    }
    span[aria-hidden="true"]{
      width: 2.4rem;
      height: 2.4rem;
      background-color: $bg_gray;
      border-color: transparent;
      span{
        width: 1.2rem;
        height: 1.2rem;
        background-color: $sky_light;
      }
    }
    &[data-selected="true"]{
      span[aria-hidden="true"]{
        width: 2.4rem;
        height: 2.4rem;
        border-color:$sky_light !important;
        span{
          width: 1.2rem;
          height: 1.2rem;
          background-color: $sky_light;
        }
      }
    }
  }

.rdo_lable {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:last-child{
    margin-left: 4rem;
  }
}

.rdo_lable:checked {
  background-color: $sky_light; 
}


.time_icon{
  @extend .position_absolute;
  top: 1.6rem;
  right: 1.6rem;
  img{
    width: 1.6rem;
    height: 1.6rem;
  }
}

// slick slider
.uploaded_img{
  .slick-slider{
    @extend .w-100;
    .slick-list{
      .slick-track{
        margin: 0;
        .slick-slide{
          max-width: 21.4rem;
          min-width: 21.4rem;
          width: 100% !important;
          height: 18.8rem;
          margin-right: 0.8rem;
        }
      }
    }
  }
}

.react-datepicker__close-icon{
  &::after{
    @extend .d_none;
  }
}

// genre list dropdown  

.genre_list{
  margin-top: -1.7rem;
  .genre_item{
    border-radius: 4rem;
    border: 0.1rem solid $bdr_clr;
    background: $bg_gray;
    padding: 1rem; 
    margin-right: 0.4rem;
    margin-bottom: 0.8rem;
    height: 3.2rem;
    span{
      color: $gray;
      font-family: 'Barlow';
      font-size: 1.4rem;
      @extend .fw_500;
      line-height: normal;
      @extend .text_capitalize;
    }
    .close_icon{
      @extend .cursor_pointer;
    }
  }
}