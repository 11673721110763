/* Sidebar Styles */
.sidebar {
  @extend .position_fixed;
  top: 0;
  left: 0;
    .website_logo{
      border-bottom: 0.1rem solid $bdr_clr;
      min-width: 22.4rem;
      height: 8rem
    }
    .sidebar_links{
      @extend .d_flex;
      @extend .justify_content_between;
      @extend .flex_direction_column;
      padding-bottom: 0.8rem;
      min-height: calc(100vh - 8rem);
      .sidebar_inner_links {
        padding: 2rem 1.6rem 2rem 3.2rem; 
        svg{
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.8rem;
        }
        &.active,&:hover{
          background: transparent;
          font-family: 'Barlow';
          color: $sky_light;
          font-weight: 400 !important;
          svg{
            path{
              stroke: $sky_light;
            }
          }
          span{
            color: $err_red;
          }
        }
      }
      p {
        @extend .d_flex_align_center;
        padding: 2rem 3.2rem; 
        text-decoration: none;
        font-family: 'Barlow';
        font-size: 1.6rem;
        font-style: normal;
        @extend .fw_400;
        line-height: normal;
        color: $mid_gray;
        border-bottom: 0.1rem solid $bdr_clr;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:hover, &.active{
          font-family: 'Barlow';
          background-color: $sky_light;
          color: $dark_bg;
          @extend .fw_600;
          img{
            filter: brightness(0%); 
          }
        }
        img,svg{
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.2rem;
        }
      }
      .sidebar_footer_links{
        .footer_link_label{
          color: $mid_gray;
          font-family: 'Barlow';
          font-size: 1.6rem;
          @extend .fw_700;
          line-height: normal;
          letter-spacing: 3.2px;
          @extend .text_uppercase;
          padding: 0 3.5rem;
          padding-bottom: 0.4rem;
        }
        p{
          padding: 2rem 3.1rem;
          &:last-child{
            border: none;
          }
          &:hover{
            color: $dark_bg !important;
            svg{
              path{
                stroke: $black;
              }
            }
          }
        }
      }
    }
  }  
  