.popup{
    padding: 4rem;
    margin: 0 1.5rem!important;
    max-width: fit-content !important;
    background: $bg_gray;
    border: 0.1rem solid $bdr_clr;
    button[aria-label="Close"]{
        @extend .d_none;
    }
    .popup_wrapper{
        .popup_image{
            width: 9.59rem;
            height: 9.59rem;
        }
        .popup_inner_content{
            margin: 4rem auto;
        }
        .popup_content{
            @extend .text_center;
            span{
                color: $mid_gray;
                font-family: 'Barlow';
                font-size: 1.8rem;
                font-style: normal;
                @extend .fw_400;
                line-height: 2.7rem;
            }
        }
    }
}

.upload_file_popup,.upload_image{
    max-width: 51.2rem !important;
    width: 100%;
}
// upload file popup
.upload_file_popup{
    .popup_data{
        margin: 4rem auto;
        .popup_inner_content{
             background: $hover_color;
             box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.30);
             padding: 1.6rem;
             margin: 0 !important;
             .upload_img,img{
                 min-width: 5.6rem;
                 max-width: 5.6rem;
                 width: 100%;
                 height: 5.6rem !important;
             }
             h5{
                 color: $gray;
                 font-family: 'Barlow';
                 font-size: 1.8rem;
                 font-style: normal;
                 @extend .fw_400;
                 line-height: 2.7rem;
             }
             span{
                 color: $mid_gray;
                 font-family: 'Barlow';
                 font-size: 1.3rem;
                 font-style: normal;
                 @extend .fw_400;
                 line-height: normal;
             }
             .upload_per{
                 span{
                     color: $lime_green;
                     font-family: 'Barlow';
                     font-size: 1.6rem;
                     font-style: normal;
                     @extend .fw_400;
                     line-height: normal;
                 }
             }
        } 
    }
    .progressBar{
        height: 0.2rem;
        .bg-warning{
            background: $lime_green !important;
        }
    }
}

// film_screening_modal

.film_screening_modal{
    max-width: 118.4rem !important;
    width: 100%;
}

// events modal

.events_modal{
    max-width: 118.4rem !important;
    width: 100%;
   .download_icon{
    @extend .position_absolute;
    @extend .cursor_pointer;
    width: 3.2rem;
    height: 3.2rem;
    top: 4.1rem;
    right: 4.6rem;
   } 
}

// alert modal
.alert_popup{
    img{
        width: 9.6rem;
        height: 9.6rem;
    }
    .popup_content {
        max-width: 44.1rem;
        width: 100%;
        h3{
            margin-bottom: 0.3rem;
        }
    }
    .popup_btns{
        button{
            transition: all 0.8s linear;
            &:hover{
                background: transparent !important;
                border: transparent !important;
                color: $sky_light !important;
            }
        }
    }
}
