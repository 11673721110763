.btn {
    @extend .barlowSemiCondensed;
    @extend .fs_20;
    line-height: normal;
    @extend .fw_600;
    @extend .text_center;
    @extend .text_capitalize;
    @extend .border_0;
    @extend .cursor_pointer;
    letter-spacing: 0.2rem;
    background-color: $sky_light;
    color: $black;
    padding: 0.9rem 1.5rem;
    outline: none;
    transition: all 0.6s;
    min-width: 21.2rem;
    min-height: 5rem;
    &:hover {
      background: $black;
      color: $white;
    }
  
    &:disabled {
      opacity: 0.5rem;
      cursor: unset;
    }
  
    &.sky_btn {
      color: #0f0f0f;
      @extend .text_center;
      @extend .barlowSemiCondensed;
      @extend .fs_20;
      letter-spacing: 2px;
      @extend .text_uppercase;
      width: 100%;
      min-height: 5rem;
      border-radius: 0.1rem;
      background: $sky_light;
      box-shadow: 0px 0px 60px 0px rgba(35, 197, 230, 0.3);
      margin-left: auto; 
      &:hover {
        background: $black;
        color: $white;
      }
  
      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
   
    &.gray_border_btn,&.sky_border_btn,&.back_btn,&.screening_btn {
      @extend .text_center;
      @extend .barlowSemiCondensed;
      @extend .fs_14;
      @extend .fw_600;
      letter-spacing: 1.4px;
      @extend .text_uppercase;
      min-height: 4.1rem;
      min-width: 21.2rem;
      max-width: 21.2rem;
      width: 100%;
      border-radius: 0.1rem;
      color: $sky_light;
      background: transparent;
      border: .1rem solid $bdr_clr;
      padding: 1.2rem 2.4rem ;
      &:hover {
        background: $black;
        color: $white;
      }
    }
    &.gray_border_btn{  
      background: $dark_bg !important; 
      border: 0.1rem solid $bdr_clr !important;
    }
    &.sky_border_btn{
      @extend .text_center;
      @extend .barlowSemiCondensed;
      @extend .fs_20;
      @extend .fw_600;
      letter-spacing: 1.4px;
      @extend .text_uppercase;
      border: .1rem solid $sky_light !important;
      min-height: 5rem !important;
    }
    &.back_btn{
      min-width: 21.2rem;
      margin-right: 2.4rem;
    }
    &.danger_btn{
      background: $err_red;
      color: $black;
      @extend .text_uppercase;
      &:hover{
        color: $err_red;
        background: $black;
      }
    }
    &.more_btn{
      color: $sky_light !important;
      font-family: 'Barlow';
      font-size: 1.2rem;
      @extend .fw_600;
      line-height: normal;
      border: 0.1rem solid $sky_light;
      background: rgba(119, 212, 225, 0.10);
      padding: 0.5rem ;
      letter-spacing: normal;
      min-width: 6.2rem;
      max-width: 6.2rem;
      min-height: 2.6rem;
      &:hover{
        background: $black;
      }
    }
    &.screening_btn{
      min-width: 31.2rem !important;
      max-width: 31.2rem !important;
      width: 100%;
      color: $gray;
      font-family: 'Barlow';
      font-size: 1.6rem;
      font-weight: 500!important;
      line-height: 2.4rem;
      padding: 1.6rem;
      text-transform: unset;
      letter-spacing: normal;
      text-align: unset;
      background: $black;
      img{
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1.4rem;
      }
    }
    &.article_btn,&.screening_btn{
      min-width: unset !important;
      max-width: unset !important;
      width: unset;
      min-height: unset !important;
    }
  }
  .exit_btn{
    font-family: Times;
    font-size: 1.6rem;
    font-style: normal;
    @extend .fw_400;
    line-height: normal;
    color: $gray;
    @extend .d_flex;
    padding: 3.2rem;
    .close_icon{
        margin-right: 0.4rem;
    }
}
.add_member_btn{
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}
