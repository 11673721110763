@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

@import "variables";
@import "fonts";
@import "classes";
@import "buttons";
@import "general";
@import "block";
@import "modal";
@import "tables";

@import 'header';
@import "sidebar";
@import 'login';