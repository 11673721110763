$black : #000;
$white : #ffffff;
$dark_bg : #0F0F0F;
$gray : #D9D9D9;
$sky_light: #77D4E1;
$bdr_clr : #333;
$mid_gray : #aaa;
$bg_gray : #1A1A1A;
$err_red: #FE7A7A;
$base_main : #F9F9F9;
$hover_color : #2A2A2A;
$lime_green: #D4E177;