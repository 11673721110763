  /* Header Styles */
  .sidebar_content{
    width: 100%;
    header {
      background-color: $dark_bg;
      padding: 1.45rem 3.2rem;
      z-index: 1000;
      max-width: calc(100vw - 22.4rem);
      width: 100%;
      @extend .position_fixed;
      top: 0;
      border-bottom: 0.1rem solid  $bdr_clr;
      min-height: 8rem;
      z-index: 9;
        .form_group{
          .custom_form_control{
            @extend .position_relative;
            .searchBox{
              min-width: 48rem;
              max-width: 48rem;
              width: 100%;
              @media screen and (max-width: 991px) {
                min-width: 30rem;
              }
            }
            .search_icon{
              @extend .position_absolute;
              top: 1.7rem;
              left: 1.6rem;
              width: 1.6rem;
              height: 1.6rem;
            }
            .close_icon{
              @extend .position_absolute;
              top: 1.4rem;
              right: 1.6rem;
              width: 2rem;
              height: 2rem;
            }
          } 
        }
      }
    }
    