.fs_13 {
    font-size: 1.3rem;
  }
  .fs_14 {
    font-size: 1.4rem;
  }
  .fs_15 {
    font-size: 1.5rem;
  }
  .fs_16 {
    font-size: 1.6rem;
  }
  .fs_18 {
    font-size: 1.8rem;
  }
  .fs_20 {
    font-size: 2rem;
  }
  .fs_24 {
    font-size: 2.4rem;
  }
  .fs_25 {
    font-size: 2.5rem;
  }
  .fs_30 {
    font-size: 3rem;
  }
  .fs_36 {
    font-size: 3.6rem;
  }
  .fs_40 {
    font-size: 4rem;
  }
  .fs_50 {
    font-size: 5rem;
  }
  .fs_54 {
    font-size: 5.4rem;
  }
  
  // font Waight
  .fw_400 {
    // @extend .montserrat_regular;
    font-weight: 400;
  }
  .fw_500 {
    // @extend .montserrat_medium;
    font-weight: 500;
  }
  .fw_600 {
    font-weight: 600;
  }
  .fw_700 {
    // @extend .montserrat_bold;
    font-weight: 700;
  }
  
  // Margin
  .m_0 {
    margin: 0;
  }
  .m_auto{
    margin: 0 auto;
  }
  .ml_auto{
    margin-left: auto;
  }
  .ml_0 {
    margin-left: 0;
  }
  .ml_10 {
    margin-left: 1rem;
  }
  .ml_16 {
    margin-left: 1.6rem;
  }
  .ml_20 {
    margin-left: 2rem;
  }
  .mr_0 {
    margin-right: 0 !important;
  }
  .mr_12 {
    margin-right: 1.2rem;
  }
  .mr_16 {
    margin-right: 1.6rem;
  }
  .mr_24 {
    margin-right: 2.4rem;
  }
  .mr_30 {
    margin-right: 3rem;
  }
  .mb_0 {
    margin-bottom: 0 !important;
  }
  .mb_24 {
    margin-bottom: 2.4rem;
  }
  .mb_48 {
    margin-bottom: 4.8rem;
  }
  .mt_0 {
    margin-top: 0 !important;
  }
  .mt_10 {
    margin-top: 1rem;
  }
  .mt_24 {
    margin-top: 2.4rem;
  }
  .mt_28{
    margin-top: 2.8rem;
  }
  .mt_30 {
    margin-top: 3rem;
  }
  .m_auto {
    margin: 0 auto;
  }
  .ml_10 {
    margin-left: 1rem;
  }
  .mb_15 {
    margin-bottom: 1.5rem;
  }
  .mb_10 {
    margin-bottom: 1.5rem;
  }
  .mb_9 {
    margin-bottom: 0.9rem;
  }
  .mb_8 {
    margin-bottom: 0.8rem;
  }
  .mb_24 {
    margin-bottom: 2.4rem;
  }
  .mb_30{
    margin-bottom: 3rem;
  }
  .mb_32 {
    margin-bottom: 3.2rem;
  }
  .mb_102 {
    margin-bottom: 10.2rem;
  }
  .mb_40 {
    margin-bottom: 4rem;
  }
  .mt_42 {
    margin-bottom: 2.625rem;
  }
  // Padding
  .p_0 {
    padding: 0;
  }
  .pl_0 {
    padding-left: 0;
  }
  .pr_0 {
    padding-right: 0;
  }
  .pb_0 {
    padding-bottom: 0 !important;
  }
  .pb_16{
    padding-bottom: 1.6rem;
  }
  .pb_24 {
    padding-bottom: 2.4rem;
  }
  .pt_0 {
    padding-top: 0;
  }
  
  .p_auto {
    padding: 0 1.5rem;
  }
  // Heading Tags
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // @extend .montserrat_regular;
    color: $gray;
  }
  h2{
    font-family: Times;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $gray;
  }
  h2.main_heading{
    font-size: 4rem;
  }
  h2.title_text {
    color: $sky_light;
    @extend .barlowSemiCondensed;
    @extend .fs_30;
    @extend .fw_600;
    line-height: normal;
    letter-spacing: 0.3rem;
    @extend .mb_9;
    @extend .text_uppercase;
    line-height: normal;

  }
  h3{
    color: $gray;
    font-family: 'Times';
    font-size: 3.2rem;
    font-style: normal;
    @extend .fw_400;
    line-height: normal;
  }
  h4{
    font-family: 'Barlow';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4rem;
    color: $gray;
    margin-bottom: 1.2rem;
  }
  h5{
    color: $gray;
    font-family: 'Barlow';
    font-size: 2.4rem;
    @extend .fw_400;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-bottom: 0.8rem
  }
  .title_acording {
    @extend .d_block;
    color: $white;
    @extend .barlow_regular;
    @extend .fs_18;
    line-height: normal;
  }
  // li tag
li {
    list-style-type: none;
    cursor: pointer;
  }
  // img
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overflow_scroll {
    overflow: auto;
  }
  .no_scroll {
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .scrollbar{
    &::-webkit-scrollbar {
      display: block;
      width: 0.6rem; 
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: $gray;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: $bdr_clr; 
    }
  }
  .inner_row_third{
    .form_group{
      &:last-child{
        max-width: 10.2rem !important; 
      }
    }
  }

  .form_group{
    &.add_pic{
      margin-right: 0.8rem !important;
      min-width: 21.4rem;
      max-width: 21.4rem;
    }
  }
  // Positions
  .position_fixed {
    position: fixed;
  }
  
  .position_relative {
    position: relative;
  }
  
  .position_absolute {
    position: absolute;
  }
  
  
  // General
  .border_0 {
    border: 0;
  }

  .border_red{
    border: 0.1rem solid $err_red !important;
  }
  
  .border_dashed{
    border : 0.2rem dashed $bdr_clr !important;
  }
  .cursor_pointer {
    cursor: pointer;
  }
  
  // Text
  .text_left {
    text-align: left;
  }
  
  .text_center {
    text-align: center;
  }
  
  .text_right {
    text-align: right;
  }
  
  .text_uppercase {
    text-transform: uppercase;
  }
  
  .text_lowercase {
    text-transform: lowercase;
  }
  
  .text_capitalize {
    text-transform: capitalize;
  }
  
  .text_decoration_none {
    text-decoration: none;
  }
  
  .radius_4 {
    border-radius: 0.4rem;
  }
  .radius_5 {
    border-radius: 0.5rem;
  }
  .radius_6 {
    border-radius: 0.6rem;
  }
  .radius_8 {
    border-radius: 0.8rem;
  }
  .radius_round {
    border-radius: 100%;
  }
  
  // Display Properties
  .d_block {
    display: block;
  }
  
  .d_inline_block {
    display: inline-block;
  }
  
  .d_none {
    display: none;
  }
  
  .d_flex {
    display: flex;
  }
  
  .d_inline_flex {
    display: inline-flex;
  }
  
  .d_flex_align_center {
    display: flex;
    align-items: center;
  }
  
  
  .align_items_baseline {
    align-items: baseline;
  }
  .align_items_center {
    align-items: center;
  }
  
  .align_items_end {
    align-items: flex-end;
  }
  
  .align_items_start {
    align-items: flex-start;
  }
  
  .justify_content_center {
    justify-content: center;
  }
  
  .justify_content_between {
    justify-content: space-between;
  }
  
  .justify_content_around {
    justify-content: space-around;
  }
  
  .justify_content_evenly {
    justify-content: space-evenly;
  }
  
  .justify_content_start {
    justify-content: flex-start;
  }
  
  .justify_content_end {
    justify-content: flex-end;
  }
  
  .d_flex_center {
    @extend .d_flex;
    @extend .align_items_center;
    @extend .justify_content_center;
  }
  
  .flex_direction_column {
    flex-direction: column;
  }
  .flex_direction_row {
    flex-direction: row !important;
  }
  .nowrap {
    flex-wrap: nowrap;
  }
  
  .flex_wrap {
    flex-wrap: wrap;
  }
  
  .word_break {
    word-break: break-all;
  }
  
  .white_space_nowrap {
    white-space: nowrap;
  }
  
  .white_space_wrap {
    white-space: normal;
  }

  .main_wrapper_header{
    @extend .pb_16;
  }
  .inner_wrapper_header{
    @extend .pb_24;
  }
  .inner_wrapper_header,.main_wrapper_header{
    img{
      width: 4rem;
      height: 4rem;
      cursor: pointer;
    }
    .video-exclaimedIcon {
      width: 2rem !important;
      height: 2rem !important;
    }
  }

  .w-48{
    width: 4.8rem;
  }
  .w-100{
    width: 100% !important; 
  }
  .w-50{
    width: 50%;
  }
  .w-16{
    width: 1.6rem;
  }
  .h-100{
    height: 100%;
  }
  .h-48{
    height: 4.8rem;
  }
  .h-16{
    height: 1.6rem;
  }
  .url_link{
    color: $sky_light !important;
    font-family: Barlow !important;
    font-size: 1.6rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  .border_red{
    border: 0.1rem solid $err_red !important;
  }
  .cms_logo{
    max-width: 17.1rem;
    min-width: 17.1rem;
    width: 100%;
    padding-top: 6.5rem;
  }
  .main_wrapper{
    max-width: 140rem;
    width: 100%;
    margin: 0 auto;
    padding:  0 5rem;
  }
  .middle_wrapper{
    padding: 9.5rem 0 35.5rem 0;   
  }
  .published{
    color: $sky_light !important;
  }
  .unpublished,.danger{
    color: $err_red !important;
  }
  .draft{
    color: $lime_green !important;
  }

.autocomplete_dropdown {
  height: 50rem;
}

.filmURL{
  color: $sky_light !important;
  font-family: 'Barlow' !important;
  font-size: 1.6rem !important;
  @extend .fw_500;
  line-height: normal !important;
}

.border-t-transparent{
  border-top-color : $sky_light;
  border-right-color: $sky_light;
}
.border-l-transparent{
  border-left-color: $sky_light;
}
.border-b-default{
  border-bottom-color: $white;
}
.common_bg_bdr{
  border: 0.1rem solid $bdr_clr;
  background: $dark_bg;
  padding: 1.6rem;
  margin-top: 2.4rem;
}
sup{
  top: -0.1em ;
}
.line_clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  // min-height: 5.4rem;
}
.line_clamp_1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
.tbl_img{
  width: 8rem;
  height: 4.5rem;
}
.field_radio{
  margin-top: 1.2rem;
}