.row{
    @extend .d_flex;
    @extend .align_items_baseline;
    // margin-bottom: 2.4rem;
    margin-bottom: 0.5rem;
    .form_group{
        width: 50%;
        &:first-child{
            margin-right: 2.4rem;
        }
        .custom_form_control{
            width: 100%;
            .field_label{
                min-height: 2.6rem;
                label,sup{
                    color: $mid_gray !important;
                    font-family: 'Barlow Semi Condensed';
                    font-size: 1.6rem;
                    @extend .fw_600;
                    line-height: normal;
                    @extend .text_uppercase;
                    padding-bottom: 0.7rem;
                    letter-spacing: 1.6px;
                    sup{
                        display: inline-block;
                        font-size: 1.6rem;
                        color: $err_red !important;
                    }
                }
            }
            input{
                width: 100%;
                border: 0.1rem solid $bdr_clr;
                background:  $bg_gray;
                border-radius: 0;
                padding: 1.4rem;
                color: $gray;
                font-family: 'Barlow';
                font-size: 1.6rem;
                @extend .fw_500;
                line-height: normal;
                height: 4.7rem;
                @extend .cursor_pointer;
                caret-color: $gray !important;
            }
            .input_field {
                .react-datepicker-wrapper{
                    @extend .w-100;
                }
            }
        }
    }
}

.inner_row{
    @extend .d_flex_align_center;
    width: 50%;
    .form_group{
        &:not(:last-child){
            margin-right: 0.8rem !important;
        }
    }
}

.custom_form_control{
    &.role_input_control{
        input{
            border: 0 !important;
            background: transparent !important;
            border-radius: 0 !important;
            padding:0 !important;
        }
        div[data-slot="input-wrapper"]{
            width: 100%;
            border: 0.1rem solid $bdr_clr;
            background: $bg_gray;
            border-radius: 0;
            padding:1.3rem 1.6rem;
            color: $gray !important;
            font-family: "Barlow";
            font-size: 1.6rem;
            line-height: normal;
            height: 4.7rem;
        }
        button[aria-haspopup="listbox"]{
            background: transparent;
            border: none;
        }
    }
}
//file upload
.custom_upload_container {
    @extend .position_relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    .custom_upload_button {
      border: 0.1rem solid $bdr_clr;
      background: $bg_gray;
      padding:4rem 1.6rem;
      color: $gray;
      text-align: center;
      font-family: Barlow;
      font-size: 1.6rem;
      font-style: normal;
      @extend .fw_500;
      line-height: 2.4rem;
      margin-top: 0.7rem;
      cursor: pointer;
      @extend .d_flex_align_center;
      @extend .justify_content_center;
      @extend .flex_direction_column;
      span{
        color: $mid_gray;
        font-family: Barlow;
        font-size: 1.6rem;
        font-style: italic;
        @extend .fw_500;
        line-height: 2.4rem;
      }
      input[type="file"] {
        @extend .position_absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }
      .upload_icon {
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 1.2rem;
        &.exclaimed_icon{
            width: 2rem;
            height: 2rem;
            margin-bottom: 0;
            margin-left: 0.8rem;
        }
      }
    }
}

.uploaded_trailer{
    .uploaded_trailer_wrapper{
        .upload_video_content{
            h4{
              @extend .line_clamp_1;  
            }
            .custom_upload_button{
                @extend .text_center;
                @extend .barlowSemiCondensed;
                @extend .fs_14;
                @extend .fw_600;
                letter-spacing: 1.4px;
                @extend .text_uppercase;
                min-height: 4.1rem;
                max-width: 19.7rem;
                min-width: 19.7rem;
                width: 100%;
                border-radius: 0.1rem;
                color: $sky_light;
                background: transparent;
                border: .1rem solid $bdr_clr;
                padding: 0;
                &:hover {
                  background: $black;
                  color: $white;
                }
            }
        }
    }
}
.inner_wrapper{
    &.inner_row_wrapper{
       .row{
            .form_group{
                width: 33.33%;
                &:not(:last-child){
                    margin-right: 2.4rem;
                }
            }
       } 
    }
    &.inner_video_wrapper{
      .row{
        &:first-child{
           .form_group{
                &:not(:last-child){
                    margin-right: 2.4rem;
                }
            } 
        }
        .dropdown{
            span{
                font-family: 'Barlow';
                font-size: 2rem;
                line-height: 2.4rem;
                color: $sky_light;
                @extend .fw_600;
            }
            button{
                background: $bdr_clr;
            }
        }
      }  
    }
    &.inner_photos_wrapper{
        .form_group{
            .uploaded_trailer
            {
                min-width: 19.5%;
                margin-right: 0.8rem;
                padding: 0;
            }
            .uploaded_img{
                width: 100%;
                margin-top: 0.8rem;
                .uploaded_img_items{
                    min-width: 21.4rem;
                    margin-right: 0.8rem;
                    @extend .position_relative;
                    height: 18.8rem;
                    &::after{
                        content: '';
                        @extend .position_absolute;
                        top: 0;
                        left: 0;
                        min-width: 21.4rem;
                        height: 18.8rem;
                        background: $black;
                        opacity: 0.5;
                    }
                    .delete_icon{
                        @extend .position_absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        width: 4.8rem;
                        height: 4.8rem;
                        img{
                            object-fit: contain;
                            width: 4.8rem;
                            height: 4.8rem;
                        }
                    }
                }
            }
        }
    }
    &.inner_video_wrapper,&.inner_cast_crew_wrapper,&.inner_article_wrapper{
        .video_accordion{
            font-family: 'Barlow';
            font-size: 2rem;
            line-height: 2.4rem;
            color: $sky_light;
            @extend .fw_600;
        }
    }
    &.inner_screening_wrapper{
        .screening_inner_wrapper{
            background: $bg_gray;
            padding: 1.6rem;
            .screening_detail{
                .screening_img{
                    max-width: 8rem;
                    min-width: 8rem;
                    width: 100%;
                    min-height: 4.5rem;
                    max-height: 4.5rem;
                    margin-right: 1.2rem;
                }
                .screening_description{
                    .film_detail{
                        margin-top: 1.2rem;
                        .duration,.rating,.genre{
                            p{
                                color: $mid_gray;
                                font-family: 'Barlow';
                                font-size: 1.8rem;
                                @extend .fw_400;
                                line-height: 2.7rem;
                                span{
                                    color: $sky_light;
                                }
                            }
                        }
                        .duration,.rating{
                            margin-right: 3.2rem;
                            @extend .position_relative;
                            &::after{
                                content: '';
                                @extend .position_absolute;
                                height: 2.4rem;
                                width: 0.1rem;
                                background: $bdr_clr;
                                top: 0.2rem;
                                right: -1.6rem;
                            }
                        }
                    }
                }
            }
            .add_screening{
                button{
                    max-width: 18.5rem;
                    min-width: 18.5rem;
                    width: 100%;
                    min-height: 5.2rem;
                    height: 5.2rem;
                    padding: 0;
                }
            }
        }
        .screening_wrapper{
            form{
                .row{
                    .form_group{
                        .dropdown{
                            svg{
                                @extend .d_none;
                            }
                        }
                    }
                }
            }
        }
    }
    &.inner_cast_crew_wrapper{
        .screening_wrapper{
            .screening_img{
                min-width: 2.4rem !important;
                max-width: 2.4rem !important;
                width: 100% !important;
                min-height: 2.4rem !important;
                max-height: 2.4rem !important;
                height: 100% !important;
                margin-right: 1.6rem;
                svg{
                    path{
                        stroke: $sky_light;
                    }
                }
            }
            .screening_description{
                span{
                    color: $gray;
                    font-family: 'Barlow';
                    font-size: 1.6rem;
                    @extend .fw_500;
                    line-height: 2.4rem;
                }
            }
            .add_screening{
                button{
                    max-width: 16.8rem;
                    min-width: 16.8rem;
                    width: 100%;
                    min-height: 4.7rem;
                    height: 4.7rem;
                }
            }
        }
    }
    .film_detail_img{
        max-width: 11.4rem;
        min-width: 11.4rem;
        width: 100%;
        height: 6.4rem;
        img{
            // object-fit: contain !important;
        }
    }
}

.dollar_sign{
    width: 4.2rem;
    height: 3.2rem;
    background: $dark_bg;
    @extend .text_center;
    padding: 0.4rem 0;
    color: $gray;
    font-family: Barlow;
    font-size: 1.6rem;
    font-style: normal;
    @extend .fw_600;
    line-height: 2.4rem;
    margin-right: 1.2rem;
}

// react date picker

.react-datepicker,.react-datepicker__header{
    background: $bg_gray;
    border: 0;
}
.react-datepicker__header{
    padding: 0.8rem 0 0 0;
}
.react-datepicker{
    padding: 2.4rem;
    border: 0.1rem solid $bdr_clr;
    max-width: 34.3rem;
    min-width: 34.3rem;
    width: 100%;
    box-shadow: 0 4rem 8rem 0 $black;
}
.react-datepicker__current-month,.react-datepicker__day{
    font-family: Barlow;
    font-size: 1.4rem;
    font-style: normal;
    @extend .fw_600;
    line-height: 2rem;
    color: $base_main;
}
.react-datepicker__triangle{
    @extend .d_none;
}
.react-datepicker__navigation{
    background: $black;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    width: 4rem;
    height: 4rem;
    top: 2.4rem;
    &.react-datepicker__navigation--next{
        right: 2.4rem;
        &::before{
            content: '';
            @extend .position_absolute;
            width: 2rem;
            height: 2rem;
            background: url("../images/svg/calender_next_arrow.svg") no-repeat;
        }
    }
    &.react-datepicker__navigation--previous{
        left: 2.4rem;
        &::before{
            content: '';
            @extend .position_absolute;
            width: 2rem;
            height: 2rem;
            background: url("../images/svg/calender_prev_icon.svg") no-repeat;
        }
    }
    .react-datepicker__navigation-icon{
        right: 0 !important;
        left: 0 !important;
        &::before{
            @extend .d_none;
        }
    }
}
.react-datepicker__month-container{
    float: none;
}
.react-datepicker__month{
    margin: 0;
}
.react-datepicker__day-names{
    margin: 2.3rem 0 0.4rem 0 ;
    .react-datepicker__day-name{
        color: $mid_gray;
        text-align: center;
        font-family: Barlow;
        font-size: 1.4rem;
        font-style: normal;
        @extend .fw_600;
        line-height: 2rem;
        letter-spacing: -0.1px;
    }
}

.react-datepicker__day-name,.react-datepicker__day{
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.8rem 1rem;
    &:not(:last-child){
        margin: 0 0.4rem 0 0;
    }
}
.react-datepicker__day--keyboard-selected,.react-datepicker__day:hover{
    background: $dark_bg;
    color: $sky_light;
}
.react-datepicker__children-container{
    margin: 0;
}
.react-datepicker__day--outside-month{
    color: $mid_gray;
}
.calender_btn{
    margin-top: 2.4rem;
    button{
        min-width: 14.3rem !important;
        max-width: 14.3rem !important;
        width: 100%;
        min-height: 4.4rem !important;
        height: 4.4rem;
        padding: 1.2rem 2rem;
        font-family: 'Barlow' !important;
        font-size: 1.4rem !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 2rem !important;
        letter-spacing: -0.1px !important;
        &.gray_border_btn{
            margin-right: 0.8rem;
            color: #f9f9f9 !important;
            &:hover{
                color: $sky_light !important;
            }
        }
    }
}

.react-datepicker__day{
    &.react-datepicker__day--disabled{
        color: $mid_gray !important;
    }
    &.react-datepicker__day--today{
        background: $dark_bg;
        color: $sky_light;
    }
}
// geosuggest suggests

.geosuggest__suggests-wrapper{
    .geosuggest__suggests {
        .geosuggest__item {
            padding: 1.85rem 0 1.85rem 5.2rem;
            border-bottom: 0.1rem solid $bdr_clr;
            background: $bg_gray;
            color: $gray;
            font-family: 'Barlow';
            font-size: 1.6rem;
            @extend .fw_400;
            line-height: normal;
            @extend .position_relative;
            &:hover, &--active{
                background: $hover_color;
            }
            &::after{
                content: '';
                @extend .position_absolute;
                top: 1.6rem;
                left: 1.6rem;
                width: 2.4rem;
                height: 2.4rem;
                background: url("../images/svg/location_icon.svg") no-repeat;
            }
        }
    }
}

.autocomplete_input_row{
    .field_label{
      min-height: 2.6rem;
      label,sup{
          color: $mid_gray !important;
          font-family: 'Barlow Semi Condensed';
          font-size: 1.6rem;
          @extend .fw_600;
          line-height: normal;
          @extend .text_uppercase;
          padding-bottom: 0.7rem;
          letter-spacing: 1.6px;
          sup{
              display: inline-block;
              font-size: 1.6rem;
              color: $err_red !important;
          }
      }
    }
    div[data-slot="input-wrapper"]{
      width: 100%;
      border: 0.1rem solid $bdr_clr;
      background:  $bg_gray;
      border-radius: 0;
      padding: 1.3rem 1.6rem;
    }
    input{
      color: $gray;
      font-family: 'Barlow';
      font-size: 1.6rem;
      @extend .fw_500;
      line-height: normal;
    }
    button{
      svg{
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .vision_icon {
    @extend .cursor_pointer;
    @extend .position_absolute;
    top: 0.5rem;
    right: 0.4rem;
    width: 2.4rem;
    height: 2.4rem;

    img {
        object-fit: contain;
    }
}

// footer

footer{
    background: $bg_gray;
    padding: 2.4rem 0;
    .policy{
        span{
            color: $mid_gray;
            font-family: 'Barlow';
            font-size: 1.6rem;
            @extend .fw_400;
            line-height: 2.4rem;
            letter-spacing: -0.32px;
            a{
                text-decoration-line: underline;
            }
        }
    }
}

// switch
.switch {
    @extend .position_relative;
    @extend .d_flex_align_center;
    @extend .justify_content_between;
    max-width: 10.2rem;
    height: 4.7rem;
    cursor: pointer;
    background-color: $bg_gray;
    border : 0.1rem solid $bdr_clr;
    padding: 0.7rem 0.8rem;
    &:before,
    &:after {
        width: 4.3rem;
        height: 3.3rem;
        color: $mid_gray;
        font-family: 'Barlow';
        font-size: 1.4rem;
        @extend .fw_500;
        line-height: normal;
        text-align: center;
        padding-top: 0.8rem;
    }
    &:before {
        content: "AM";
    }
    &:after {
        content: "PM";
    }
    input {
        @extend .position_absolute;
        clip: rect(0 0 0 0);
    }
    .toggle_button {
        @extend .position_absolute;
        left: 0.8rem;
        width: 4.3rem;
        height: 3.3rem;
        transition: all 0.2s ease-in-out;
        &.am,&.pm{
            &:before {
                @extend .d_block;
                width: 4.3rem;
                height: 3.3rem;
                font-family: 'Barlow';
                font-size: 1.4rem;
                @extend .fw_500;
                line-height: normal;
                color: $bg_gray;
                background-color: $sky_light;
                content: "AM";
                @extend .text_center;
                top: 0.8rem;
                padding-top: 0.8rem;
            }
        }
        &.pm{
            left: 5rem !important;
            &:before {
                content: "PM";
            }
        }
    }
}

// toast
li{
    &:has(> .toast){
        width: 44.1rem;
        right: 0;
    }
    .toast{
        border: 0.1rem solid $mid_gray;
        background: $bdr_clr;
        box-shadow: 0px 40px 80px 0px $black;
        padding: 1.6rem;
        top: 7.6rem;
        svg{
            margin-right: 1.2rem;
            path{
                stroke: $sky_light;
            }
        }
        span{
            color: $gray;
            font-family: 'Barlow';
            font-size: 1.6rem;
            @extend .fw_400;
            line-height: normal;
        }
        .close_icon{
            top: 1.8rem;
            right: 1.6rem;
            svg{
                margin-right: 0;
                width: 1.6rem;
                height: 1.6rem;
                path{
                    stroke: $gray;
                }
            }
        }
        .alert_icon{
            path{
                stroke: $err_red !important;
            }
        }
    }   
}

// change password

.change_pwd{
    .form_group{
        @extend .position_relative;
        .vision_icon{
            top: 3.8rem;
            right: 1rem;
        }
    }
}

div[data-slot="content"]:has(> .status_dropdown_items){
    min-width: 16rem;
    min-height: 5.6rem;
}


.status_dropdown_items{
    ul{
        li{
            padding: 1.8rem 1.6rem;
            min-width: 16rem;
            min-height: 5.6rem;
            &.unpublished{
                span{
                    color: $err_red !important;
                }
            }
            &.published{
                span{
                    color: $sky_light !important;
                }
            }
            span{
                color: $gray;
                font-family: 'Barlow';
                font-size: 1.4rem;
                @extend .fw_400;
                line-height: normal;
            }
        }
    }
}